<template>
  <v-container fluid>
    <v-row
      class="pt-4"
      no-gutters
    >
      <v-col
        class="mr-8"
        cols="5"
      >
        <v-card class="px-4 pb-2">
          <v-card-title>
            <h3 style="word-break: break-word" class="text-subtitle-1 font-weight-bold">
              {{ $t('languagesSettings.languagesAvailableForUsers') }}
            </h3>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="lang in languages"
                :key="lang.nameISO"
                dense
              >
                <v-row
                  class="align-center"
                  no-gutters
                >
                  <v-col cols="9">
                    {{ getLanguageName(lang.nameISO) }}
                  </v-col>
                  <v-col class="d-flex align-center" cols="2">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="lang.active"
                      :disabled="lang.nameISO === 'pl-PL' || lang.nameISO === 'en-US' ? true : false"
                      dense
                      hide-details
                      @change="toggleActiveLanguage($event, lang.nameISO)"
                    />
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row
      class="mt-6"
      no-gutters
    >
      <v-col>
        <v-card elevation="1">
          <v-card-title>
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('languagesSettings.listOfFieldsToTranslate') }}
            </h3>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-tabs v-model="activeTab">
              <v-tab>{{ $t('languagesSettings.helpdeskTopicsTabName') }}</v-tab>
              <v-tab>{{ $t('languagesSettings.checklists.tabName') }}</v-tab>
              <v-tab>{{ $t('languagesSettings.issueCategoryTabName') }}</v-tab>
              <v-tab>{{ $t('languagesSettings.vehicleTypeTabName') }}</v-tab>
              <v-tab>{{ $t('languagesSettings.vehicleOptionTabName') }}</v-tab>
              <v-tab>{{ $t('languagesSettings.freightTabName') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item>
                <HelpdeskTopicTranslationsTab :languages="languages" />
              </v-tab-item>
              <v-tab-item>
                <ChecklistTranslationsTab :languages="languages" />
              </v-tab-item>
              <v-tab-item>
                <IssueCategoryTranslationsTab :languages="languages" />
              </v-tab-item>
              <v-tab-item>
                <VehicleTypeTranslationsTab :languages="languages" />
              </v-tab-item>
              <v-tab-item>
                <VehicleOptionTranslationsTab :languages="languages" />
              </v-tab-item>
              <v-tab-item>
                <FreightTranslationsTab :languages="languages" />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'

import HelpdeskTopicTranslationsTab from './Tabs/Languages/HelpdeskTopicTranslationsTab.vue'

export default {
  name: 'Languages',
  components: {
    HelpdeskTopicTranslationsTab,
    ChecklistTranslationsTab: () => import('./Tabs/Languages/ChecklistTranslationsTab.vue'),
    IssueCategoryTranslationsTab: () => import('./Tabs/Languages/IssueCategoryTranslationsTab.vue'),
    VehicleTypeTranslationsTab: () => import('./Tabs/Languages/VehicleTypeTranslationsTab.vue'),
    VehicleOptionTranslationsTab: () => import('./Tabs/Languages/VehicleOptionTranslationsTab.vue'),
    FreightTranslationsTab: () => import('./Tabs/Languages/FreightTranslationsTab.vue')
  },
  data () {
    return {
      languages: [],
      activeTab: 0
    }
  },
  methods: {
    async getLanguages () {
      const { data: languages } = await Service.get('/LanguageSettings/GetAll')
      this.languages = languages
    },
    async toggleActiveLanguage (event, nameISO) {
      try {
        await Service.put('/LanguageSettings/Update', {
          nameISO: nameISO,
          active: event
        })
      } finally {
        await this.getLanguages()
      }
    },
    getLanguageName (iso) {
      return iso.substring(0, iso.indexOf('-')).toUpperCase()
    }
  },
  mounted () {
    this.getLanguages()
  }
}

</script>
